<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-sm">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/orders">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>All Orders</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h6 class="d-print-none">
              Week {{GetWeek(start)}} (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>).
            </h6>
            <p class="d-none d-print-flex">
              {{ fD(new Date(date)) }}
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class=" gx-3 d-print-none">
            <li>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>

                      <div class="col-md-5 text-center">
                        <h5>Start</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Date </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="my-3">
        <a
          href="#pickDates"
          data-toggle="modal"
          class="btn btn-white btn-dim btn-outline-primary"
        >
          <em class="icon ni ni-calendar"></em>
          <span> Week {{GetWeek(start)}} </span>
        </a>
      </div>
    </div>

    <div class="ng-block">
      <div class="ng-block ng-block-lg" v-if="processing">
        <div class="card card-bordered loading">
          <div class="justify-content-center text-center">
            <div class="spinner-grow text-primary m-3" role="status">
              <span class="sr-only">Processing Data...</span>
            </div>
            <h6 class="mb-4">Processing Data...</h6>
          </div>
        </div>
      </div>
      <div class="row my-4" v-else>
        <div class="col-2 mb-3">
          <button
            v-if="!downloadLoading"
            class="btn btn-white btn-dim btn-outline-primary"
            @click="fetchRawMaterialExport"
          >
            Download Report
          </button>
          <button
            v-else
            class="btn btn-white btn-dim btn-outline-primary"
            disabled
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Fetching Report</span>
            </div>
            Fetching Report
          </button>
        </div>
        <div
          class="col-12 mb-3"
          v-for="(d, i) in getDates(start, end)"
          :key="i"
        >
          <div
            style="font-size: 11px;font-weight: 800;letter-spacing: 0.12em;background: #00964c;"
          >
            <div class="align-middle" style="padding: 20px 12px">
              <h6 class="text-left text-white">
                {{ fD(new Date(d), "E, do MMMM yyyy") }}
              </h6>
            </div>
          </div>

          <div class="ng-block ng-block-lg mt-3">
            <table class="table table-tranx raw-materials-table">
              <thead class="tb-tnx-heads text-uppercase">
                <tr class="tb-tnx-item">
                  <th></th>
                  <th
                    class="text-center align-middle text-truncate"
                    style="font-size: 11px;padding: 0.3rem"
                    v-for="v in varieties"
                    :key="v.id"
                  >
                    {{ v.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(c, i) in customers" :key="i">
                  <tr
                    class="tb-tnx-item tb-tnx-head"
                    style="font-size: 11px;padding: 0.3rem"
                    v-if="
                      raw_materials[d].orders[c.id] &&
                        isFilled(raw_materials[d].orders[c.id])
                    "
                  >
                    <td class="tb-tnx-info align-middle">
                      {{ c.name }}
                    </td>
                    <td
                      class="text-center align-middle"
                      v-for="(o, i) in raw_materials[d].orders[c.id]"
                      :key="i"
                    >
                      {{ addCommas(o) }}
                    </td>
                  </tr>
                </template>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">Packed Ahead</td>
                  <td
                    class="text-center align-middle"
                    style="padding: 0.75rem 0.5rem !important;"
                    v-for="(o, i) in raw_materials[d]?.packed_ahead"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">Packed Behind</td>
                  <td
                    class="text-center align-middle"
                    style="padding: 0.75rem 0.5rem !important;"
                    v-for="(o, i) in raw_materials[d]?.packed_behind"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Total Sold
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.total_sold"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Pack Out
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.pack_out"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Raw Mat. Required
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.raw_materials"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Stock
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.stock"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <template v-for="(f, i) in farms" :key="i">
                  <tr
                    class="tb-tnx-item tb-tnx-head"
                    style="font-size: 11px;padding: 0.3rem"
                    v-if="
                      raw_materials[d].picks[f.id] &&
                        isFilled(raw_materials[d].picks[f.id])
                    "
                  >
                    <td class="tb-tnx-info align-middle">
                      {{ f.name }} {{ raw_materials[d]?.picks_day_short }}
                    </td>
                    <td
                      class="text-center align-middle"
                      v-for="(o, i) in raw_materials[d].picks[f.id]"
                      :key="i"
                    >
                      {{ addCommas(o) }}
                    </td>
                  </tr>
                </template>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    {{ prevDay(d) }} Picks Consumed
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]
                      ?.previous_days_picks_consumed"
                    :key="i"
                  >
                    <span
                      :class="[parseInt(o) > 0 ? 'green-background' : '']"
                      >{{ addCommas(o) }}</span
                    >
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Total Gross Available
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.total_gross_available"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Day's Pick
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.days_pick"
                    :key="i"
                  >
                    <span
                      :class="[parseInt(o) > 0 ? 'green-background' : '']"
                      >{{ addCommas(o) }}</span
                    >
                  </td>
                </tr>
                <tr
                  class="tb-tnx-item"
                  style="font-size: 11px;padding: 0.3rem;font-weight: 800"
                >
                  <td class="tb-tnx-info align-middle">
                    Balance Gross
                  </td>
                  <td
                    class="text-center align-middle"
                    v-for="(o, i) in raw_materials[d]?.balance_gross"
                    :key="i"
                  >
                    {{ addCommas(o) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card card-bordered"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import { AxiosResponse } from "axios";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  props: {
    today: {
      type: String
    }
  },
  setup(props) {
    const form = ref({});

    const raw_materials = ref([
      {
        "2022-01-01": {
          orders: {},
          packed_ahead: {},
          total_sold: {},
          raw_materials: {},
          stock: {},
          days_pick: {},
          pack_out: {},
          total_gross_available: {},
          balance_gross: {}
        }
      }
    ]);

    const processing = ref(false);
    const downloadLoading = ref(false);
    const varieties = ref([]);
    const customers = ref([]);
    const farms = ref([]);
    const orders = ref({
      orders: {},
      packed_ahead: {},
      total_sold: {},
      raw_materials: {},
      stock: {},
      days_pick: {},
      pack_out: {},
      total_gross_available: {},
      balance_gross: {}
    });

    const day = ref(0);
    const date = ref(new Date());

    function friday() {
      const f = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return f.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(f.setDate(f.getDate() - (f.getDay() - 1) + 3));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = new Date(d);
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function prevDay(d: any) {
      return moment(d)
        .subtract(1, "days")
        .format("ddd");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = Number(value);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      // return value;
    }

    function updatePackedAhead(index: any) {
      const indexValue = (document.getElementById(
        "packed" + index
      ) as HTMLInputElement).value;
      orders.value.packed_ahead[index] = indexValue;
    }

    function nextDay(today: any) {
      const date = moment(today);
      return date.add(1, "days").format("ddd, Do MMMM yyyy");
    }

    function downloadCSV(csv: BlobPart) {
      let csvFile;
      let downloadLink;

      // CSV file
      // eslint-disable-next-line prefer-const
      csvFile = new Blob([csv], { type: "text/csv" });

      // Download link
      // eslint-disable-next-line prefer-const
      downloadLink = document.createElement("a");

      // File name
      downloadLink.download =
        "RawMaterials Week" +
        fD(start.value, "ww") +
        " " +
        fD(date.value, "doMMMyy") +
        ".csv";

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);

      // Hide download link
      downloadLink.style.display = "none";

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      downloadLink.click();

      downloadLoading.value = false;
    }

    function exportExcel() {
      downloadLoading.value = true;
      const csv = [];
      const rows = document.querySelectorAll(
        "#raw-materials-table" + day.value + " tr"
      );

      for (let i = 0; i < rows.length; i++) {
        const row = [];
        const cols = rows[i].querySelectorAll("td, th");

        for (let j = 0; j < cols.length; j++) row.push(cols[j].innerHTML);

        if (row[0] == "Packed Ahead") {
          const input = row.slice(1);
          const packdAhead = ["Packed Ahead"];
          input.forEach(score => {
            const htmlObject = document.createElement("div");
            htmlObject.innerHTML = score;
            const inputId = htmlObject.getElementsByTagName("input")[0].id;
            const indexValue = (document.getElementById(
              inputId
            ) as HTMLInputElement).value;
            packdAhead.push(indexValue);
          });
          csv.push(packdAhead);
        } else {
          csv.push(row.join(","));
        }
      }

      // Download CSV file
      downloadCSV(csv.join("\n"));
    }

    function downloadReport(response: AxiosResponse<any>) {
      const fileUrl = window.URL.createObjectURL(response.data);
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute(
        "download",
        "RawMaterials Week" +
          fD(start.value, "ww") +
          " " +
          fD(date.value, "doMMMyy") +
          ".xlsx"
      );
      document.body.appendChild(fileLink);

      fileLink.click();

      downloadLoading.value = false;
    }

    async function fetchVarieties() {
      const res = await http.get(`/api/varieties-sorted?fetch=all`);

      return res.data;
    }

    async function fetchCustomers() {
      const res = await http.get(`/api/users?role=customer&fetch=all`);
      return res.data;
    }

    async function fetchFarms() {
      const res = await http.get(`/api/farms-sorted?fetch=all`);
      return res.data;
    }

    // function changeOrderValues(d: any) {
    //   d = moment(d).format("YYYY-MM-DD");
    //   orders.value = raw_materials.value[d];
    // }

    function fetchRawMaterialExport() {
      downloadLoading.value = true;
      http
        .get(
          `/api/reporting/raw-materials?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          downloadReport(response);
        });
    }

    function fetchWeeklyRawMaterials(start_value: any, end_value: any) {
      processing.value = true;

      fetchVarieties().then((v: any) => {
        varieties.value = v;
      });
      fetchCustomers().then((c: any) => {
        customers.value = c;
      });
      fetchFarms().then((f: any) => {
        farms.value = f;
      });
      http
        .get(
          `/api/raw-materials?start=${fD(start_value, "yyyy-MM-dd")}&end=${fD(
            end_value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          raw_materials.value = res.data;
          const today = fD(start_value, "yyyy-MM-dd");
          orders.value = res.data[today];

          processing.value = false;
        });
    }

    function getDailyOrders(i: any, date: any) {
      setDay(i, date);
      // fetchWeeklyRawMaterials(date);
    }

    function addPackedAhead(date: any) {
      http
        .post("/api/raw-materials", {
          date: date,
          packed_ahead: orders.value.packed_ahead
        })
        .finally(() => {
          fetchWeeklyRawMaterials(start.value, end.value);
        });
    }

    function isFilled(o: any) {
      const data = Object.values(o);

      const filled = data.filter((f: any) => {
        return Number(f) > 0;
      });

      return filled.length > 0;
    }

    onMounted(() => {
      fetchWeeklyRawMaterials(start.value, end.value);

      date.value = start.value;
    });

    watch(
      () => start.value,
      s => {
        fetchWeeklyRawMaterials(start.value, end.value);
      }
    );
    // watch(
    //   () => date.value,
    //   d => {
    //     changeOrderValues(d);
    //   }
    // );

    return {
      fD,
      setDay,
      start,
      end,
      day,
      date,
      getDates,
      processing,
      raw_materials,
      varieties,
      customers,
      orders,
      isFilled,
      farms,
      nextDay,
      getDailyOrders,
      exportExcel,
      addCommas,
      downloadLoading,
      updatePackedAhead,
      addPackedAhead,
      fetchRawMaterialExport,
      form,
      prevDay
    };
  }
});
</script>
